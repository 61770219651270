import "./website-assets/css/style.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/auth/Login";
import Home from "./components/Home";
import Register from "./components/auth/Register";
import Accounts from "./components/Pages/Accounts";
import Items from "./components/Pages/Items";
import Layout from "./components/Layout";
import Dashboard from "./components/Pages/Dashboard";
import Profile from "./components/Pages/Profile";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/"
            element={
              <Layout component="osrsGold">
                <Home component="osrsGold" />
              </Layout>
            }
          />
          <Route
            path="/rs3-gold"
            element={
              <Layout component="rs3Gold">
                <Home component="rs3Gold" />
              </Layout>
            }
          />
          <Route
            path="/osrs-accounts"
            element={
              <Layout component="osrsAccounts">
                <Home component="osrsAccounts" />
              </Layout>
            }
          />
          <Route
            path="/rs3-accounts"
            element={
              <Layout component="rs3Accounts">
                <Home component="rs3Accounts" />
              </Layout>
            }
          />
          <Route
            path="/osrs-items"
            element={
              <Layout component="osrsItems">
                <Home component="osrsItems" />
              </Layout>
            }
          />
          <Route
            path="/rs3-items"
            element={
              <Layout component="rs3Items">
                <Home component="rs3Items" />
              </Layout>
            }
          />
          <Route
            path="/all-osrs-accounts"
            element={
              <Layout component="OSRS">
                <Accounts component="OSRS" />
              </Layout>
            }
          />
          <Route
            path="/all-rs3-accounts"
            element={
              <Layout component="RS3">
                <Accounts component="RS3" />
              </Layout>
            }
          />
          <Route
            path="/all-osrs-items"
            element={
              <Layout component="OSRS">
                <Items component="OSRS" />
              </Layout>
            }
          />
          <Route
            path="/all-rs3-items"
            element={
              <Layout component="RS3">
                <Items component="RS3" />
              </Layout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/profile"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
