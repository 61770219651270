import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import favicon from "../../website-assets/images/favicon.png";
import Pusher from "pusher-js";
import { sendMessage, storeChatImage } from "../services/api";

const Chat = (props) => {
  const notify = () =>
    toast("Agent Is Online Now!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const fileInputRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [newmessage, setNewMessage] = useState();
  const [chatRoomId, setChatRoomId] = useState(null);
  const [pusher, setPusher] = useState(null);
  const [statusChannel, setStatusChannel] = useState(null);
  const [messageChannel, setMessageChannel] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const scrollableRef = useRef(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await storeChatImage(formData);
      const imageUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/${response.data}`;
      const msg = {
        sender_type: "1",
        message: imageUrl,
      };
      setMessages([...messages, msg]);
      var userid = sessionStorage.getItem("user_id");
      if (userid === null) {
        userid = Math.floor(10000000 + Math.random() * 90000000);

        sessionStorage.setItem("user_id", userid);
      }
      var sendablemessage = imageUrl;
      const messsage = await sendMessage(sendablemessage, userid);
      setChatRoomId(messsage.data.chat_room_id);
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleNewmessageChange = (e) => {
    const newValue = e.target.value;
    setNewMessage(newValue);
  };
  useEffect(() => {
    if (props.firstmessage) {
      handleSendMessage(null, props.firstmessage);
    }
  }, [props.firstmessage]);

  const handleSendMessage = async (e) => {
    if (e) e.preventDefault();
    var userid = sessionStorage.getItem("user_id");
    if (userid === null) {
      userid = Math.floor(10000000 + Math.random() * 90000000);

      sessionStorage.setItem("user_id", userid);
    }
    try {
      var sendablemessage = props.firstmessage || newmessage;

      props.setFirstMessage(null);
      const msg = {
        sender_type: "1",
        message: sendablemessage,
      };
      setMessages([...messages, msg]);
      setNewMessage("");
      const response = await sendMessage(sendablemessage, userid);
      setChatRoomId(response.data.chat_room_id);
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  const handleChatToggle = (e) => {
    e.preventDefault();
    console.log(props);
    props.setShowChat(!props.showChat);
  };

  function closeChat(e) {
    e.preventDefault();
    props.setShowChat(!props.showChat);
  }

  useEffect(() => {
    if (!pusher) {
      Pusher.logToConsole = true;
      const pusherInstance = new Pusher("5789d689258a2ac6ae07", {
        cluster: "ap2",
      });
      setPusher(pusherInstance);
    }
    var userid = sessionStorage.getItem("user_id");
    if (userid === null) {
      userid = Math.floor(10000000 + Math.random() * 90000000);

      sessionStorage.setItem("user_id", userid);
    }
    return () => {
      if (messageChannel) {
        messageChannel.unbind_all();
        pusher.unsubscribe(`availability.${chatRoomId}`);
      }
      if (statusChannel) {
        statusChannel.unbind_all();
        pusher.unsubscribe(`chat-room.${chatRoomId}`);
      }
    };
  }, [pusher]);

  useEffect(() => {
    // Scroll to bottom when messages change
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (pusher && chatRoomId) {
      const channel = pusher.subscribe(`availability.${chatRoomId}`);
      channel.bind("availability-status", (data) => {
        notify();
      });
      setStatusChannel(channel);

      const messagechannel = pusher.subscribe(`chat-room.${chatRoomId}`);
      messagechannel.bind("agent-sent-message", (data) => {
        setMessages((prevMessages) => [...prevMessages, data.message]);
      });
      setMessageChannel(messagechannel);

      // Cleanup on chatRoomId change
      return () => {
        channel.unbind_all();
        pusher.unsubscribe(`availability.${chatRoomId}`);
        messagechannel.unbind_all();
        pusher.unsubscribe(`chat-room.${chatRoomId}`);
      };
    }
  }, [chatRoomId, pusher]);

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        class={`chat-widget position-fixed end-0 bottom-0 me-3 rounded-2 overflow-hidden bg-white border shadow ${
          props.showChat ? "showChat" : ""
        }`}
      >
        <div class="position-relative inner">
          <div class="bg-dark-ben align-items-center px-3 py-2 text-white d-flex justify-content-between">
            <span>
              <strong class="d-block mb-1">24 / 7 Live Chat</strong>
              <small class="fs-13">Online</small>
            </span>
            <a href="" onClick={closeChat} class="text-white close-chat">
              <i class="fa fa-minus"></i>
            </a>
          </div>
          <div class="d-flex align-items-center gap-2 bg-white p-2 box-shadow border-bottom">
            <img src={favicon} alt="Support Icon" width="30" class="border" />
            <span>
              <strong class="fs-13 d-blcok">Customer Support</strong>
              <p class="fs-12">Use code "Ben" to save on order</p>
            </span>
          </div>
          <div class="scrolable pb-4" ref={scrollableRef}>
            {messages.map((msg, index) =>
              msg.sender_type === "0" ? (
                <div key={index} className="chat p-3 pb-0 support-chat">
                  <img
                    src={favicon}
                    alt="Support Icon"
                    width="30"
                    className="border rounded-circle float-start me-2"
                  />
                  <div className="d-block overflow-hidden mt-2">
                    <strong>BenGP</strong>
                    {isValidUrl(msg.message) ? (
                      <img
                        src={msg.message}
                        alt="Uploaded"
                        className="bg-primary-ben text-white p-3 mt-2 rounded-1"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <p className="lh-150 d-block fs-12 bg-primary-ben p-3 mt-2 rounded-1 text-white">
                        {msg.message}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div key={index} className="chat p-3 user-chat">
                  <img
                    src={favicon}
                    alt="Support Icon"
                    width="30"
                    className="border rounded-circle float-end ms-2"
                  />
                  <div className="d-block overflow-hidden mt-2">
                    <strong className="text-end d-block">
                      {sessionStorage.getItem("user_name")
                        ? sessionStorage.getItem("user_name")
                        : "Anonymous"}
                    </strong>
                    {isValidUrl(msg.message) ? (
                      <img
                        src={msg.message}
                        alt="Uploaded"
                        className="bg-dark-ben text-white p-3 mt-2 rounded-1"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <p className="lh-150 d-block fs-12 bg-dark-ben text-white p-3 mt-2 rounded-1">
                        {msg.message}
                      </p>
                    )}
                  </div>
                </div>
              )
            )}
            <br />
            <br />
          </div>
          <div class="position-absolute bottom-0 start-0 end-0 w-100 border-top position-absolute mb-5">
            <textarea
              class="form-control rounded-0 resize-none shadow-none border-end-0 border-start"
              rows="3"
              placeholder="Type..."
              value={newmessage}
              onChange={handleNewmessageChange}
            ></textarea>
          </div>
          <div class="text-end btns">
            <button
              type="button"
              className="btn btn-secondary-ben btn-sm me-2"
              onClick={handleButtonClick}
            >
              <i className="fa fa-paperclip fs-12"></i>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <a
              href=""
              class="btn btn-primary-ben btn-sm me-2"
              onClick={handleSendMessage}
            >
              <i class="fa fa-paper-plane fs-12"></i>
            </a>
          </div>
        </div>
      </div>
      <a
        href="javascript:;"
        class="chat-btn btn-primary-ben rounded-circle p-4 position-fixed bottom-0 end-0 mb-3 me-3 z-3"
        id="chatBtn"
        onClick={handleChatToggle}
      >
        <i class="fa fa-comment fs-3"></i>
      </a>
    </>
  );
};
export default Chat;
