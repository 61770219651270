import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
});

export const register = (formData) => API.post("/user-register", formData);
export const login = (formData) => API.post("/user-login", formData);

export const osrsgoldRate = () => API.get("/osrs-gold-price");
export const rs3goldRate = () => API.get("/rs3-gold-price");
export const osrsAccounts = () => API.get("/osrs-accounts");
export const osrsProducts = () => API.get("/osrs-products");
export const rs3Accounts = () => API.get("/rs3-accounts");
export const rs3Products = () => API.get("/rs3-products");
export const binancePayment = () => API.get("/binance-create-order");
export const placeOrder = (token, formData) =>
  API.post(`/binance/create-order`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getUserOrders = (token, userId) =>
  API.post(`/user/orders`, userId, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const placeOsrsGoldOrder = (token, formData) =>
  API.post(`/binance/create-gold-order`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const updateProfile = (token, formData) =>
  API.post(`/update-profile`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const sendMessage = (message, userid) =>
  API.post(`/send-message`, {
    user_id: userid,
    message: message,
  });

export const storeChatImage = (formData) =>
  API.post(`/store-chat-image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
