import React, { useEffect, useState } from "react";
import { osrsAccounts, rs3Accounts } from "../services/api";
import logo from "../../website-assets/images/logo-footer.png";

const Accounts = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    setLoader(true);
    if (props.component === "OSRS") {
      const response = await osrsAccounts();
      setAccounts(response.data);
    } else {
      const response = await rs3Accounts();
      setAccounts(response.data);
    }
    setLoader(false);
  };

  return (
    <div className="login-register mt-5">
      <em class="over position-fixed bg-black opacity-85 bottom-0 top-0 end-0 start-0"></em>
      <div class="container position-relative z-1 mt-4">
        <div class="row mt-4">
          <div class="col-12 mt-4">
            <div class="row mt-4">
              <div class="col-sm-6 my-4">
                <div class="input-group mb-4 mt-2 bg-light-ben position-relative w-280">
                  <input
                    type="text"
                    class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                    placeholder="Search..."
                  />
                  <a href="javascript:;" class="btn pt-1 me-1 border-0">
                    <i class="fa fa-search text-light-ben mt-3"></i>
                  </a>
                </div>
              </div>
              <div class="col-sm-6 my-4">
                <div class="input-group my-2 bg-light-ben w-280 float-end">
                  <select
                    class="form-select shadow-none rounded-0 bg-light-ben border-light-ben text-light-ben"
                    aria-label="Recommended"
                  >
                    <option selected>Recommended</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
              <div class="col-6">
                <h4 class="h5 fw-bold mb-0 pb-4 text-white">OSRS Accounts</h4>
              </div>
              <div class="col-6">
                <p class="text-light-ben float-end fs-12">3,350 Accounts</p>
              </div>
              {loader ? (
                <>
                  <div className="loader-card text-center my-4 h-20">
                    <div
                      className="spinner-border text-warning"
                      role="status"
                    ></div>
                  </div>
                </>
              ) : (
                accounts.map((account) => (
                  <div class="col-md-6">
                    <div class="items-box p-3 position-relative transition mb-4">
                      <div class="d-flex gap-3">
                        <div class="img-holder position-relative overflow-hidden transition rounded-1">
                          <span class="bg-danger fs-12 py-1 px-2 d-inline-block position-absolute start-0 top-0 mt-1 ms-1 text-white">
                            NEW
                          </span>
                          <img
                            src={logo}
                            alt="Logo"
                            class="transition img-fluid rounded-1"
                            width="100"
                            height="100"
                          />
                        </div>
                        <div class="details-account">
                          <strong class="d-block text-primary-ben mt-1">
                            {account.title}
                          </strong>
                          <div>
                            <div class="d-flex gap-2">
                              <div class="d-flex gap-1 my-3 fs-12">
                                <i
                                  class="fa fa-trophy text-primary-ben fs-11"
                                  title="Account Level"
                                ></i>{" "}
                                <span class="text-white">
                                  Level {account.level}
                                </span>
                              </div>
                              <div class="d-flex gap-1 my-3 fs-12">
                                <i
                                  class="fa fa-dollar text-primary-ben fs-11"
                                  title="Price"
                                ></i>{" "}
                                <span class="text-white">{account.cost}</span>
                              </div>
                              <div class="d-flex gap-1 my-3 fs-12">
                                <i
                                  class="fa fa-basket-shopping text-primary-ben fs-11"
                                  title="Sold"
                                ></i>{" "}
                                <span class="text-white">555</span>
                              </div>
                            </div>
                            <a
                              href="javascript:;"
                              class="btn btn-primary-ben rounded-0 px-3 my-1 fs-12"
                            >
                              BUY NOW
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Accounts;
